<template>
  <b-card>
    <section>
      <div class="d-flex justify-content-start flex-wrap">
        <b-button
          variant="adn"
          data-action-type="new"
          class="mr-25 mb-1"
          @click="
            (v) => {
              openModal();
            }
          "
        >
          <feather-icon
            icon="UploadIcon"
            class="mr-25"
          />
          {{ $t('uploadGuardiansFromExcelFile') }}
        </b-button>

        <b-button
          variant="outline-adn"
          class="ml-50 mb-1"
          data-action-type="new"
          @click="
            (v) => {
              downloadExcelSheet();
            }"
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-25"
          />
          {{ $t('downloadExcelSheet') }}
        </b-button>
      </div>
    </section>

    <b-modal
      ref="edit-modal"
      no-close-on-backdrop
      centered
      size="lg"
      hide-footer
      :title="$t('exportNewGuardians')"
    >
      <g-form @submit="uploadItems">
        <b-row>
          <b-col cols="12">
            <div>
              <input
                accept=".xlsx, .xls"
                type="file"
                class="excel-upload-input"
                @change="handleFileUpload( $event )"
              >
              <b-alert
                v-if="file !== ''"
                show
                fade
                class="mt-2 text-center"
                variant="success"
              >
                <div class="alert-body">
                  <span>{{ $t('importSuccsefly') }} {{ file.name }}</span>
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-permission="$route.meta.permission"
              class="mx-1"
              type="submit"
              variant="gradient-primary"
              data-action-type="save"
            >
              {{ $t('save') }}
            </b-button>
            <b-button
              class="mx-1"
              variant="outline-primary"
              @click="closeModal"
            >
              {{ $t('Close') }}
            </b-button>
          </b-col>
        </b-row>

      </g-form>
    </b-modal>

    <!-- create table contain all guardians defined and opertaions allowed -->
    <g-table
      ref="guardians-table"
      :items="items"
      :columns="tableColumns"
      :is-busy="isTableBusy"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      @filtered="onFiltered"
      :createButton="{ visiable: true , permission: 'addGuardians'}"
      :searchInput="{ visiable: true }"
      @on-create="
        (v) => {
          $router.push({ name: 'guardian-new' });
        }
      "
    >
      <template #actions="{ item }">
        <div class="text-nowrap">
          <!-- define edit button -->
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission="'editGuardians'"
            data-action-type="edit"
            variant="custom"
            class="btn-icon"
            size="sm"
            @click="editGuardian(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
              class="mx-25 clickable"
            />
          </b-button>

          <!-- define delete button -->
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deleteGuardians'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>

<script>
import saveAs from 'file-saver';
import GTable from '@/pages/Shared/Table.vue';
import { rows } from '@/libs/acl/Lookups';

export default {
  components: {
    GTable,
  },

  data() {
    return {
      items: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 25,
      rows: rows,
      modal: false,
      isTableBusy: false,
      filter: {
        orderClause: null,
      },
      file: ''
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('code'), sortable: true },
        {
          key: this.isRight ? 'arabicName' : 'englishName',
          field: this.isRight ? 'arabicName' : 'englishName',
          label: this.$t('name'),
          sortable: true,
        },
        { key: 'mobileNumber', label: this.$t('Phone'), sortable: true },
        { key: 'email', label: this.$t('email'), sortable: false },
        {
          key: this.isRight ? 'nationalityNameAr' : 'nationalityNameEn',
          field: this.isRight ? 'nationalityNameAr' : 'nationalityNameEn',
          label: this.$t('nationality'),
          sortable: true,
        },
        { key: 'actions' },
      ];
    },
  },

  mounted() {
    this.getItems();
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getItems() {
      this.get({ url: 'Guardians' }).then((data) => {
        this.items = data;
      })
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: 'Guardians', id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getItems();
          });
        }
      );
    },
    editGuardian(item) {
      this.$router.push({
        name: 'guardian-edit',
        params: { id: item.id },
      });
    },
    openModal() {
      this.$refs['edit-modal'].show();
      this.file = ''
    },
    closeModal() {
      this.$refs['edit-modal'].hide();
      if (this.file) this.getItems();
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      if (!this.isExcel(this.file)) {
        this.file = '';
        this.doneAlert({ text: this.$t('Only supports upload .xlsx, .xls, .csv suffix files'), type: 'error' });
        return false
      }
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name)
    },
    uploadItems() {
      if (this.file === '' || !this.file.name) {
        this.doneAlert({ text: this.$t('addFileFirst'), type: 'warning' });
        return;
      }
      const formData = new FormData();
      formData.append('file', this.file);
      this.create({ url: 'guardians/data-migration', data: formData })
      .then(() => {
        this.closeModal();
        this.doneAlert({ text: this.$t('savedSuccessfully') });
      });
    },
    downloadExcelSheet() {
      this.get({ url: 'guardians/data-migration', responseType: 'arraybuffer' }).then((data) => {
        saveAs(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'Guardians.xlsx');
      });
    },
  }
};
</script>

<style></style>
